import styled from "styled-components";
import { Link } from "react-router-dom";

const StyledButton = styled.button`
    border: none;
    width: 100%;
    background-color: transparent;
    cursor: pointer;
    transition: 0.2s;
    font-size: 1.2em;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
    background-color: ${props => props.$fill ? props.theme.color.gray : 'transparent'};
    padding: 1em;
    text-align: center;
    cursor: pointer;
    display: block;
    transition: 0.2s;
    border: ${props => props.$fill ? 'none' : `0.1em solid ${props.theme.color.green}`};
    width: 100%;
    border-radius: ${props => props.container || props.roundedCorners ? '0.6em' : '0'};

    ${StyledButton} {
        color: ${props => props.$fill ? 'white' : props.theme.color.green};
    }

    &:hover {
        background-color: ${props => props.theme.color.green};
        
        ${StyledButton} {
            color: white;
        }
    }

    &:active ${StyledButton} {
        transform: scale(0.95);
    }        
`;

export default function Button({ nodes = { }, children, roundedCorners, fill = true, style = {}, buttonStyle, onClick }) {
    const text = nodes.text ? nodes.text : children;
    fill = nodes.fill ?? fill;
    const { container } = nodes;
    style.borderRadius = roundedCorners || container ? '0.6em' : 0;
    const link = nodes.link ? nodes.link : '';

    return (
        <section style={{ width: '100%', }} className={container ? 'container' : null}>
            <StyledLink onClick={onClick} to={link} target={link.includes('://') ? '_blank' : '_self'} style={style} $fill={fill}>
                <StyledButton style={buttonStyle}>{text}</StyledButton>
            </StyledLink>
        </section>
    );
}