import styled from "styled-components";
import { device } from "../device";
import CountUp from 'react-countup';
import Button from "./Button";

const strapiUrl = process.env.REACT_APP_STRAPI_URL;

const AwardCount = styled.section`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5em;
`;

const StyledCountUp = styled(CountUp)`
    font-size: 2.5em;
    color: ${props => props.theme.color.gray};
    transition: color 0.3s;
`;

const Award = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-basis: calc(100% / 6 - 0.5em);
    flex-grow: 1;
    text-align: center;
    padding: 2em 1em;
    gap: 1em;
    background-color: ${props => props.theme.color.semiGray};
    border-radius: 0.6em;

    p {
        font-size: 1.2em;
    }

    &:hover img {
        opacity: 1;
        filter: grayscale(0%);
    }

    &:hover ${StyledCountUp} {
        color: ${props => props.theme.color.green};
    }

    &:hover p {
        color: ${props => props.theme.color.green};
    }

    @media ${device.tablet} {
        flex-basis: calc(100% / 3 - 0.5em);
    }

    @media ${device.mobileL} {
        flex-basis: calc(50% - 0.5em);
    }

    @media ${device.mobileS} {
        flex-basis: 100%;
    }
`;

const AwardImage = styled.img`
    filter: grayscale(100%);
    opacity: 0.5;
    transition: filter 0.3s, opacity 0.3s;
    width: 50%;
`;

const P = styled.p`
    margin: 0;
    color: ${props => props.theme.color.gray};
    transition: color 0.3s;
`;

const ProjectAwards = styled.section`
    display: flex;
    gap: 3em;
    justify-content: center;
    margin: 2em 0;

    .award {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-basis: 5em;

        img {
            opacity: 0.5;
            transition: 0.3s;
        }

        p {
            transition: 0.3s;
            text-align: center;
            font-size: 1.2em;
        }

        &:hover {
            p {
                color: var(--green);
            }

            img {
                opacity: 1;
            }
        }
    }
`;

export default function AwardSection({ nodes }) {
    const projectAwards = !nodes.awardCount[0].count;

    return (
        <>
            {projectAwards
            ?
            <ProjectAwards className="container">
                {
                    nodes.awardCount.map((award, index) => (
                        <div className="award" key={index}>
                            <img src={strapiUrl + award.logo.data.attributes.url} alt={award.award} />
                            <p>{award.award}</p>
                        </div>
                    ))
                }
            </ProjectAwards>
            :
            <AwardCount className='container'>
                {
                    nodes.awardCount.map((award, index) => (
                        <Award key={index}>
                            <AwardImage src={strapiUrl + award.logo.data.attributes.url} alt={award.award} />
                            <StyledCountUp enableScrollSpy scrollSpyOnce end={award.count} duration={2} />
                            <P>{award.award}</P>
                        </Award>
                    ))
                }
                {nodes.buttonText && nodes.buttonLink ?
                <Button roundedCorners={true} nodes={{link: nodes.buttonLink}} fill={false}>
                    {nodes.buttonText}
                </Button> : null}
            </AwardCount>}
        </>
    )
}