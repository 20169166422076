import React, { useRef, useState, useEffect } from 'react';
import Typewriter from 'typewriter-effect';

export default function TypewriterText({ nodes, className }) {
    const text = nodes.text;
    const container = nodes.container;
    const typewriterRef = useRef();
    const containerRef = useRef();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(entry.isIntersecting);
                if (entry.isIntersecting && typewriterRef.current) {
                    typewriterRef.current.start();
                }
            },
            { threshold: 0.1 }
        );

        const currentContainerRef = containerRef.current;

        if (currentContainerRef) {
            observer.observe(currentContainerRef);
        }

        return () => {
            if (currentContainerRef) {
                observer.unobserve(currentContainerRef);
            }
        };
    }, []);

    return (
        <div ref={containerRef} className={container ? 'container' : className}>
            <Typewriter 
                onInit={(typewriter) => {
                    typewriter.typeString(text);
                    typewriterRef.current = typewriter;
                    if (isVisible) {
                        typewriter.start();
                    }
                }}
                options={{
                    delay: 20
                }}
                component={'h1'}
            />
        </div>
    );
}