import { Menu } from './';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BsEnvelopeFill, BsLinkedin, BsYoutube } from 'react-icons/bs';
import { device } from './device';
import { Button } from './main';

const strapiUrl = process.env.REACT_APP_STRAPI_URL;

const Image = styled.img`
    width: 100%;
    height: auto;
`;

const ImageHover = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    opacity: 0;
    transition: opacity 0.2s;
`;

const FooterImage = styled(Link)`
    position: relative;
    display: block;

    &:hover ${ImageHover} {
        opacity: 1;
    }
`;

const StyledFooter = styled.div`
    position: relative;
    padding-bottom: 2em;
    color: white;
    text-align: center;
    background-color: ${props => props.theme.color.green};
    z-index: 998;
    width: 100%;

    a {
        color: white;
        text-decoration: none;
    }

    hr {
        border: none;
        border-top: 1px solid white;
    }
`;

const listStyle = {
    listStyle: 'none',
    padding: 0,
    margin: 0,
};

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    padding-top: 1em;

    > * {
        flex-basis: calc(100% / 3 - 1em);
    }

    hr.tablet {
        display: none;
        width: 100%;
        margin: 0;
    }

    @media ${device.tablet} {
        flex-direction: column;
        gap: 2em;

        p {
            text-align: center;
        }

        hr.tablet {
            display: block;
        }
    }

    ul li a {
        color: rgba(255, 255, 255, 0.5);
        transition: 0.2s;

        &.current-page, &:hover {
            color: white;
        }
    }
`;

const SocialIcons = styled.div`
    text-align: right;

    p {
        color: white;
    }

    a {
        font-size: 1.2em;
        margin-left: 0.5em;
        color: var(--green);
        background-color: rgba(255, 255, 255, 0.5);
        padding: 0.5em;
        display: inline-flex;
        border-radius: 50%;
        transition: 0.2s;

        &:hover {
            transform: scale(1.1);
            background-color: white;
        }

        &:active {
            transform: scale(1);
        }
    }
`;

const FooterLogo = styled(Link)`
    display: flex;
    align-items: center;
    gap: 0.5em;
    text-align: left;

    img {
        filter: grayscale(100%) brightness(9999);
        width: 3em;
    }

    @media ${device.mobileS} {
        flex-direction: column;
        text-align: center;
    }
`;

const ContactButton = styled.div`
    position: fixed;
    bottom: 1em;
    right: 0;
    width: 100%;
    pointer-events: none;
    display: flex;
    justify-content: right;
    z-index: 997;

    a {
        font-size: 2em;
        color: rgba(255, 255, 255, 0.5);
        background-color: var(--green);
        padding: 0.5em;
        display: inline-flex;
        border-radius: 50%;
        transition: 0.2s;
        pointer-events: all;
        box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);

        &:hover {
            transform: scale(1.1);
            color: white;
        }

        &:active {
            transform: scale(1);
        }
    }
`;

export default function Footer({ menuItems, socialLinks, image, imageHover, footerLogo, tagline, currentPage }) {

    return (
        <footer>
            <StyledFooter>
                {currentPage !== 'Contact'
                    ?
                    <>
                        <Button nodes={{link: '/contact'}}>Get in touch</Button>
                        <FooterImage to='/contact' title='Contact us!'>
                            <Image src={image} alt='office' />
                            <ImageHover src={imageHover} alt='office' />
                        </FooterImage>
                    </>
                    :
                    null
                }
                <Container className='container'>
                    <FooterLogo className="logo" to="/">
                        <img src={strapiUrl + footerLogo.url} alt='krieen' />
                        {tagline}
                    </FooterLogo>
                    <hr className='tablet container' />
                    <Menu items={menuItems} listStyle={listStyle} listItemStyle={{ padding: '0.4em' }} style={{ paddingTop: '1em' }} currentPage={currentPage} />
                    <hr className='tablet container' />
                    <SocialIcons>
                        <p>Get in touch!</p>
                        <Link to={socialLinks.linkedin} target="_blank"><BsLinkedin /></Link>
                        <Link to={socialLinks.youtube} target="_blank"><BsYoutube /></Link>
                        <Link to={'mailto:' + socialLinks.email} target="_blank"><BsEnvelopeFill /></Link>
                    </SocialIcons>
                </Container>
                <div className='container'>
                    <hr />
                </div>
                <Link to="/">
                    © krieen {new Date().getFullYear()}
                </Link>
            </StyledFooter>
            <ContactButton className='container'>
                <Link to='/contact' title='Contact us!'><BsEnvelopeFill /></Link>
            </ContactButton>
        </footer>
    )
}