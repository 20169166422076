import styled from "styled-components"
import { device } from "../device"
import ReadMore from "./ReadMore";

const strapiUrl = process.env.REACT_APP_STRAPI_URL;

const ClientLogo = styled.img`
    width: 3.2em !important;
    position: absolute;
    box-shadow: none !important;
    right: 1.5em;
    top: 0.5em;
    border-radius: 0.5em;
    padding: 0.2em;
    mix-blend-mode: multiply;
    filter: grayscale(1);
    opacity: 0.8;
    transition: 0.3s;
`;

const StyledTestimonial = styled.div`
    display: flex;
    gap: 2em;
    align-items: start;
    padding: 1.5em;
    background-color: ${props => props.theme.color.semiGray};
    border-radius: 0.6em;
    position: relative;

    img {
        width: 7em;
        border-radius: 0.6em;
        box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
    }

    p {
        margin: 0;
    }

    hr {
        border: none;
        border-top: 1px solid ${props => props.theme.color.gray};
        width: 70%;
        margin: 1em 0;
        transition: 1s;
    }

    &:hover hr {
        width: 100%;
    }

    &:hover ${ClientLogo} {
        opacity: 1;
        filter: grayscale(0);
    }

    @media ${device.tablet} {
        flex-wrap: wrap;
    }
`;

const Name = styled.span`
    color: ${props => props.theme.color.green};
`;

const TestimonialHeader = styled.div`
    display: flex;
    justify-content: space-between;
`;

export default function Testimonial({ avatarUrl, name, position, client, quote, project }) {
    const readMoreButton = project.data && {
        link: '/work/' + project.data.attributes.slug,
        text: 'View project',
    }

    return (
        <StyledTestimonial>
            <img src={avatarUrl} alt={name} />
            <div style={{ width: '100%' }}>
                <TestimonialHeader>
                    <p>
                        <Name>{name}</Name> · {position} at {client.name}
                    </p>
                    {client.logo.data && client.logo.data.attributes.url ? <ClientLogo src={strapiUrl + client.logo.data.attributes.url} alt={client.name} /> : null}
                </TestimonialHeader>
                <hr />
                {quote.excerpt ? <ReadMore nodes={{full: <p>{quote.full}</p>, excerpt: <p>{quote.excerpt}</p>}} button={readMoreButton} /> : <p>{quote.full}</p>}
            </div>
        </StyledTestimonial>
    )
}