export default function Loader() {
    return (
        <div
            className="preload"
            style={{
                opacity: 1,
                pointerEvents: 'none',
                position: 'fixed',
                zIndex: '10000000',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                backgroundColor: 'white',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <div className="preload-container">
                <svg
                    width="100px"
                    version="1.1"
                    viewBox="0 0 210 297"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g
                        transform="matrix(11.868 0 0 11.868 6.8527 49.132)"
                        data-name="Layer 1"
                    >
                        <rect
                            id="preloadRect"
                            style={{
                                fill: 'var(--green)',
                                stroke: 'none',
                            }}
                            transform="rotate(45 8.3345 8.3423)"
                            x="2.57"
                            y="2.57"
                            width="11.54"
                            height="11.54"
                        ></rect>
                        <path
                            id="preloadK"
                            style={{
                                fill: 'white',
                                stroke: 'none',
                            }}
                            d="m5.17 4.77h1.9v2.45l2.39-2.48h2.24l-2.6 2.53 2.9 4.37h-2.3l-1.9-3.08-0.73 0.7v2.38h-1.9z"
                        ></path>
                    </g>
                </svg>
            </div>
        </div>
    );
}