import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { Global, Loader } from './components';
import { checkStatus, parseJSON } from "./utils/apiUtils";
import { ThemeProvider } from 'styled-components';
import { Outlet } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";

const headers = { "Content-Type": "application/json" };
const strapiUrl = process.env.REACT_APP_STRAPI_URL;
const theme = {
  color: {
    green: "#4A7729",
    gray: "rgb(140, 130, 121)",
    semiGreen: "#4a77294d",
    semiGreen2: "#4a7729e5",
    semiGreen3: "#4a772934",
    semiGray: "rgba(140, 130, 121, 0.2)",
    semiGray2: "rgba(140, 130, 121, 0.1)",
    semiGray2Opaque: "rgb(244, 243, 242)",
    semiGray2SemiOpaque: "rgba(244, 243, 242, 0.5)",
    semiGray3: "rgba(140, 130, 121, 0.95)",
    semiGray4: "rgba(140, 130, 121, 0.3)",
  },
  variables: {
    headerHeight: '70px',
    containerPadding: '20px',
    containerWidth: '1200px',
    borderRadius: '0.6em',
  }
};

function App() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const [router, setRouter] = useState(createBrowserRouter([{ route: "/", element: <Loader /> }]));

  useEffect(() => {
    Promise.all([
      fetch(`${strapiUrl}/api/pages?fields=slug&pagination[pageSize]=100`, { headers, method: "GET" })
        .then(checkStatus)
        .then(parseJSON),
      fetch(`${strapiUrl}/api/projects?fields=slug&pagination[pageSize]=100`, { headers, method: "GET" })
        .then(checkStatus)
        .then(parseJSON),
      fetch(`${strapiUrl}/api/articles?fields=slug&pagination[pageSize]=100`, { headers, method: "GET" })
        .then(checkStatus)
        .then(parseJSON)
    ])
      .then(([pages, projects, articles]) => {
        const routes = [];

        pages = pages.data;
        for (const page of pages) {
          let slug = page.attributes.slug;
          let children = [];

          switch (slug) {
            case 'work':
              children = projects.data.map(project => {
                return { path: project.attributes.slug, element: <Global postType="projects" id={project.id} /> };
              });
              children.push({
                index: true,
                element: <Global postType="pages" id={page.id} />
              });
              routes.push({
                path: slug,
                element: <Outlet />,
                children: children
              });
              break;

            case 'news':
              children = articles.data.map(article => {
                return { path: article.attributes.slug, element: <Global postType="articles" id={article.id} /> };
              });
              children.push({
                index: true,
                element: <Global postType="pages" id={page.id} />
              });
              routes.push({
                path: slug,
                element: <Outlet />,
                children: children
              });
              break;

            default:
              slug = slug === 'home' ? '/' : slug;
              routes.push({
                path: slug,
                element: <Global postType="pages" id={page.id} />
              });
          }
        }

        routes.push({
          path: '*',
          element: <Global postType="404" />
        });

        setRouter(createBrowserRouter(routes));
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 200);
      });
  }, []);

  if (error) {
    return <div>An error occurred: {error.message}</div>;
  }


  if (loading) {
    return <Loader />;
  }

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="cookieConsent"
        style={{ background: "rgba(255, 255, 255, 0.8)", color: 'var(--gray)', fontSize: "0.85em", padding: '0', margin: 0 }}
        buttonStyle={{ color: "white", backgroundColor: 'var(--green)', borderRadius: '0.6em', padding: '0.6em 1em' }}
        expires={150}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </ThemeProvider>
  );
}

export default App;
