import { React, useEffect, useState } from "react";
import { checkStatus, parseJSON } from "../utils/apiUtils";
import * as MainComponents from "./main";
import { Hero } from "./main";
import { Helmet } from "react-helmet-async";
import Loader from "./Loader";

const headers = { "Content-Type": "application/json" };
const strapiUrl = process.env.REACT_APP_STRAPI_URL;

export default function Main({ postType, id }) {
    const [error, setError] = useState(null);
    const [post, setPost] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (id) {
            fetch(`${strapiUrl}/api/${postType}/${id}?populate=deep`, { headers, method: "GET" })
                .then(checkStatus)
                .then(parseJSON)
                .then(({ data }) => {
                    setPost(data);
                    setLoading(false);
                })
                .catch((error) => {
                    setError(error);
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [id, postType]);

    if (error) {
        return <div>An error occurred: {error.message}</div>;
    }

    if (loading) {
        return <Loader />;
    }

    const components = {};
    if (id) {
        for (const index in post.attributes.body) {
            const component = post.attributes.body[index];
            const type = component.__component.split(".")[1].replace(/-(\w)/g, (match, p1) => p1.toUpperCase()).replace(/^(\w)/, (match, p1) => p1.toUpperCase());
            if (MainComponents[type]) {
                components[index] = type;
            } else {
                console.log("Component not found: " + type);
            }
        }
    }

    return (
        <main>
            {post.attributes.seo ? <Helmet><title>KRIEEN | {post.attributes.seo.metaTitle}</title></Helmet> : null}
            {post.attributes.seo && post.attributes.seo.metaDescription ? <Helmet><meta name="description" content={post.attributes.seo.metaDescription} /></Helmet> : null}
            {post.attributes.seo && post.attributes.seo.metaImage.data ? <Helmet><meta property="og:image" content={strapiUrl + post.attributes.seo.metaImage.data.attributes.url} /></Helmet> : null}
            {postType === 'projects' || postType === 'articles'
                ?
                <Hero nodes={post.attributes.banner} />
                :
                null
            }
            {Object.keys(components).map((index) => {
                const Component = MainComponents[components[index]];
                return <Component key={index} nodes={post.attributes.body[index]} />;
            })}
            {postType === '404' ? <div className="container"><Helmet><meta name="robots" content="nodindex" /></Helmet><MainComponents.TypewriterText nodes={{text: "Error 404."}} /><p>The page you requested does not exist... Sorry!</p></div> : null}
        </main>
    );
}