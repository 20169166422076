import Media from "./Media";
import { Carousel as CarouselJS } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import styled from "styled-components";

const StyledCarouselJS = styled(CarouselJS)`
    .carousel {
        padding-bottom: 2em;
    }

    .carousel-status {
        text-shadow: 0 0 0.4em rgba(0, 0, 0, 0.5);
        right: 1em;
        font-size: 0.8em;
    }

    .carousel-slider .control-arrow {
        top: calc(50% - 1.5em / 2);
        height: 1.5em;
        border-radius: 0.3em;
        margin: 0 0.2em;
        opacity: 1;
        background-color: ${props => props.theme.color.semiGray3};

        &:hover {
            background-color: ${props => props.theme.color.green};
        }
    }

    .control-dots {
        bottom: 0em;
        margin: 0.2em 0;

        .dot {
            box-shadow: 0 0 0.8em rgba(0, 0, 0, 0.6);
            background-color: ${props => props.theme.color.semiGray3};

            &.selected {
                background-color: ${props => props.theme.color.green};
            }
        }
    }

    .slider-wrapper {
        border-radius: 0.6em;
    }

    .slide section {
        margin: 0 !important;
    }
`;

export default function Carousel({ nodes }) {
    return (
        <StyledCarouselJS showThumbs={false} autoPlay infiniteLoop swipeable className="container">
            {nodes.media.data.map((media, index) => (
                <Media key={index} nodes={{media: {data: {attributes: media.attributes}}}} />
            ))}
        </StyledCarouselJS>
    )
}