export default function BurgerX({onClick, isOpen}) {
    return (
        <button className="burgerX" onClick={onClick} style={{background: 'none', border: 'none', cursor: 'pointer'}}>
            <svg style={{width: '24px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="black" strokeWidth="2">
                <line
                    x1="0"
                    y1="8"
                    x2="24"
                    y2="8"
                    style={{
                        transform: isOpen ? 'rotate(45deg) translateY(4px)' : 'rotate(0)',
                        transformOrigin: '12px 12px',
                        transition: '0.2s'
                    }}
                />
                <line
                    x1="0"
                    y1="16"
                    x2="24"
                    y2="16"
                    style={{
                        transform: isOpen ? 'rotate(-45deg) translateY(-4px)' : 'rotate(0)',
                        transformOrigin: '12px 12px',
                        transition: '0.2s'
                    }}
                />
            </svg>
        </button>
    )
}