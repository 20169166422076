import { checkStatus, parseJSON } from "../../utils/apiUtils";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { device } from "../device";
import ReadMore from "./ReadMore";
import { VideoEmbed, Button } from './';
import Testimonial from "./Testimonial";
import Loader from "../Loader";

const strapiUrl = process.env.REACT_APP_STRAPI_URL;
const headers = { "Content-Type": "application/json" };

const AwardedProject = styled.div`
    background-color: ${props => props.$highlighted ? props.theme.color.semiGreen3 : props.theme.color.semiGray2};
    transition: 0.2s;
    display: flex;
    gap: 2em;
    align-items: start;
    padding: 1.5em;
    border-radius: 0.6em;

    img {
        width: 3em;
    }

    .client-logo {
        width: 5em;
        mix-blend-mode: multiply;
        margin: -1em 0;
    }

    &:hover {
        background-color: ${props => props.$highlighted ? props.theme.color.semiGreen : props.theme.color.semiGray};
    }

    p {
        margin: 0;
    }

    hr {
        border: none;
        border-top: 1px solid ${props => props.theme.color.gray};
        width: 70%;
        margin: 1em 0;
        transition: 1s;
    }

    &:hover hr {
        width: 100%;
    }

    @media ${device.tablet} {
        flex-wrap: wrap;
    }

    h2 {
        color: var(--green);
        margin: 0;
        margin-bottom: 0.3em;
    }
`;

const StyledAwardedWorkSection = styled.section`
    display: flex;
    flex-direction: column;
    gap: 0.5em;
`;

export default function AwardedWorkSection({ nodes }) {
    const [awardedProjects, setAwardedProjects] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(`${strapiUrl}/api/awarded-projects?populate=deep`, { headers, method: "GET" })
            .then(checkStatus)
            .then(parseJSON)
            .then(({ data }) => {
                setAwardedProjects(data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
            });
    }, []);

    if (error) {
        return <div>An error occurred: {error.message}</div>;
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <StyledAwardedWorkSection className="container">
            {
                awardedProjects.map((project, index) => {
                    const attr = project.attributes;
                    const tAttr = attr.testimonial ? attr.testimonial.testimonial.data.attributes : null;

                    return (
                        <AwardedProject key={index} $highlighted={attr.highlighted}>
                            <img src={strapiUrl + attr.award.data.attributes.url} alt={attr.award.data.attributes.name.split('.')[0]} />
                            <div style={{ width: '100%' }}>
                                <img className="client-logo" src={strapiUrl + attr.client.data.attributes.logo.data.attributes.url} alt={attr.client.data.attributes.name} />
                                <p>
                                    {attr.client.data.attributes.name}{attr.tagline ? ' · ' + attr.tagline : null}
                                </p>
                                <hr />
                                {attr.description || attr.testimonial || attr.video
                                    ?
                                    <ReadMore
                                        nodes={{
                                            full:
                                                <>
                                                    <h2>{attr.title}</h2>
                                                    <p>{attr.description}</p>
                                                    {attr.video ? <VideoEmbed roundedCorners={true} className='video-embed' nodes={attr.video} /> : null}
                                                    {attr.testimonial ? <Testimonial avatarUrl={strapiUrl + tAttr.avatar.data.attributes.url} name={tAttr.name} position={tAttr.position} client={tAttr.client.data.attributes} quote={attr.testimonial.quote} project={tAttr.project} /> : null}
                                                    {attr.project.data ? <Button roundedCorners={true} nodes={{ link: '/work/' + attr.project.data.attributes.slug }} style={{ marginTop: '0.5em' }}>Visit project</Button> : null}
                                                </>,
                                            excerpt: <h2>{attr.title}</h2>,
                                        }}
                                        readLess={false}
                                        initiallyExpanded={attr.expanded}
                                    />
                                    :
                                    <h2>{attr.title}</h2>}
                            </div>
                        </AwardedProject>
                    )
                })
            }
        </StyledAwardedWorkSection>
    )
}