import styled from "styled-components";

const StyledUl = styled.ul`
    list-style: none;
    display: flex;
    gap: 0.5em;
    flex-wrap: wrap;
    margin: 0.5em auto;

    li {
        background-color: ${props => props.theme.color.semiGray};
        padding: 0.3em 0.6em;
        border-radius: 0.6em;
        transition: 0.2s;
        font-size: 0.85em;

        &:hover {
            background-color: ${props => props.theme.color.semiGray4};
        }
    }
`;

export default function Tags({ nodes }) {
    const tags = nodes.tagName.map((tag, index) => {
        return (
            <li key={index}>
                {tag.name}
            </li>
        )
    });

    return (
        <StyledUl className="container">
            {tags}
        </StyledUl>
    )
}