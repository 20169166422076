import React from 'react';
import { Link } from 'react-router-dom';

export default function Menu({ items, onClickAction, listStyle, style, listItemStyle, currentPage }) {
    return (
        <nav style={style}>
            <ul style={listStyle}>
                {Object.keys(items).map((key) => (
                    <li style={listItemStyle} key={key}>
                        <Link className={currentPage.split('/')[0] === items[key].name ? 'current-page' : ''} to={items[key].path} onClick={onClickAction}>{items[key].name}</Link>
                    </li>
                ))}
            </ul>
        </nav>
    );
}