import styled from "styled-components";

const HR = styled.hr`
    width: 100%;
    border: none;
    border-top: 1px solid ${props => props.theme.color.gray};
`;

export default function Sepparator({nodes}) {
    return (
        <div className="container" id={nodes && nodes.anchor ? nodes.anchor : null}>
            <HR />
        </div>
    );
}
