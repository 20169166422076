import styled from "styled-components";

const StyledVideoEmbed = styled.div`
    padding: 56.25% 0 0 0;
    position: relative;
    margin: 0.5em 0;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`;
export default function VideoEmbed({ props, nodes, className, roundedCorners, style }) {

    const embedUrl = 'https://www.youtube-nocookie.com/embed/' + nodes.url.split('.be/')[1]

    return (
        <div className={nodes.container ? 'video-embed container' : 'video-embed'}>
            <StyledVideoEmbed className={className} style={style}>
                <iframe title='YouTube' width='560' height='315' src={embedUrl} style={{ border: 'none', borderRadius: nodes.container || roundedCorners ? '0.6em' : 0, }} />
            </StyledVideoEmbed>
        </div>
    );
}