import CountUp from 'react-countup';
import TypewriterText from './TypewriterText';
import styled from 'styled-components';
import { device } from '../device';

const Section = styled.section`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1em;
    margin-top: 2em;
`;

const Counter = styled.div`
    flex-basis: calc(100% / 4 - 1em);
    text-align: center;

    @media ${device.laptop} {
        flex-basis: calc(100% / 2 - 1em);
    }

    @media ${device.mobileL} {
        flex-basis: 100%;
    }
`;

const StyledCountUp = styled(CountUp)`
    font-size: 2em;
    color: ${props => props.theme.color.green};
`;

export default function CounterSection({ nodes }) {

    return (
        <Section className='container'>
                {
                    nodes.counter.map((counter, index) => (
                        <Counter key={index}>
                            {counter.number ? <StyledCountUp enableScrollSpy scrollSpyOnce end={counter.number} duration={2} prefix={counter.prefix ? counter.prefix : ''} suffix={counter.suffix ? counter.suffix : ''} /> : null}
                            {counter.typewriter ? <TypewriterText nodes={{text: counter.typewriter}} /> : null}
                            <p>{counter.text}</p>
                        </Counter>
                    ))
                }
        </Section>
    )
}