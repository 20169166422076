import { useEffect, useState } from "react";
import { checkStatus, parseJSON } from "../utils/apiUtils";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Header, Footer, Main, ScrollToTop } from './';
import { useLocation } from "react-router-dom";
import Loader from "./Loader";


const headers = { "Content-Type": "application/json" };
const strapiUrl = process.env.REACT_APP_STRAPI_URL;

export default function Global({ postType, id }) {
    const [error, setError] = useState(null);
    const [global, setGlobal] = useState(null);
    const [loading, setLoading] = useState(true);
    const [navbarMenu, setNavbarMenu] = useState([]);
    const [footerMenu, setFooterMenu] = useState([]);
    const location = useLocation();
    let currentPath = location.pathname === "/" ? "/home" : location.pathname;
    currentPath = currentPath.replaceAll("/", "").replace('-', ' ').replace(/\b\w/g, char => char.toUpperCase());

    useEffect(() => {
        fetch(`${strapiUrl}/api/global?populate=deep`, { headers, method: "GET" })
            .then(checkStatus)
            .then(parseJSON)
            .then(({ data }) => {
                setGlobal(data);
                setNavbarMenu(data.attributes.navbarMenu.entry);
                setFooterMenu(data.attributes.footerMenu.entry);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
            });
    }, []);


    if (error) {
        return <div>An error occurred: {error.message}</div>;
    }

    if (loading) {
        return <Loader />;
    }

    const logo = global.attributes.logo.data.attributes;
    const tagline = global.attributes.tagline;
    const socialLinks = global.attributes.social;

    return (
        <ScrollToTop>
            <HelmetProvider>
                <Helmet>
                    <title>KRIEEN</title>
                    <meta name="description" content={global.attributes.seo.metaDescription} />
                </Helmet>
                <Header logo={logo} tagline={tagline} menuItems={navbarMenu} socialLinks={socialLinks} currentPage={currentPath} />
                <Main key={`${postType}-${id}`} postType={postType} id={id} />
                <Footer menuItems={footerMenu} footerLogo={logo} tagline={tagline} socialLinks={socialLinks} image={strapiUrl + global.attributes.footerImage.data.attributes.url} imageHover={strapiUrl + global.attributes.footerImageHover.data.attributes.url} currentPage={currentPath} />
            </HelmetProvider>
        </ScrollToTop>
    );
}