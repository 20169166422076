import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { TypewriterText, Sepparator } from './';
import styled from 'styled-components';

const strapiUrl = process.env.REACT_APP_STRAPI_URL;

const StyledSection = styled.section`
    background-color: ${props => props.theme.color.semiGray};
    padding: 2em 0;
    margin: 2em 0;

    form {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
    }

    button {
        border: 0.1em solid ${props => props.theme.color.green};
        width: 100%;
        cursor: pointer;
        transition: 0.2s;
        font-size: 1.2em;
        padding: 1em;
        color: ${props => props.theme.color.green};
        border-radius: 0.6em;

        &:hover {
            background-color: ${props => props.theme.color.green};
            color: white;
        }
    }

    h1 {
        margin-top: 0;
    }

    input, textarea {
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        border: none;
        display: block;
        color: ${props => props.theme.color.gray};
        font-size: 1em;
        padding: 1em;
        border-radius: 0.6em;

        &:focus-visible {
            outline: none;
            outline: 0.1em solid ${props => props.theme.color.gray};
        }
    }

    textarea {
        min-height: 6em;
    }
`;

export default function ContactForm() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [isSent, setIsSent] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const recaptchaRef = useRef(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!recaptchaRef.current) {
            alert('reCAPTCHA not loaded.');
            return;
        }

        setIsSubmitting(true);

        recaptchaRef.current.executeAsync()
            .then((token) => {
                if (!token) {
                    throw new Error('reCAPTCHA token is missing.');
                }
                const payload = {
                    token: token,
                    formData: {
                        name: formData.name,
                        email: formData.email,
                        message: formData.message
                    }
                };

                return fetch(`${strapiUrl}/api/ezforms/submit`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                });
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            })
            .then((data) => {
                setIsSent(true);
                setFormData({ name: '', email: '', message: '' });
            })
            .catch((error) => {
                console.error('Error:', error);
                alert(`Something went wrong: ${error.message}`);
            })
            .finally(() => {
                setIsSubmitting(false);
                recaptchaRef.current.reset();
            });
    };

    return (

        isSent ?
            <>
                <Sepparator />
                <div className="container">
                    < TypewriterText nodes={{ text: 'Thanks for reaching out! We’ll be in touch soon.' }} />
                </div >
                <Sepparator />
            </>
            :
            <StyledSection>
                <form className="container" id="contact-form" onSubmit={handleSubmit}>
                    <TypewriterText nodes={{ text: 'Get in touch' }} />
                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder='Name'
                        required
                        value={formData.name}
                        onChange={handleChange}
                    />

                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder='Email'
                        required
                        value={formData.email}
                        onChange={handleChange}
                    />

                    <textarea
                        id="message"
                        name="message"
                        placeholder='Message'
                        required
                        value={formData.message}
                        onChange={handleChange}
                    ></textarea>

                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        size="invisible"
                    />
                    <button type="submit" disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                </form>
            </StyledSection>
    );
}