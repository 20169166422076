import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { NewsPopup } from './';
import styled from 'styled-components';
import { VideoEmbed } from './';

const strapiUrl = process.env.REACT_APP_STRAPI_URL;
const responsiveBreakpoint = 600;

const StyledHero = styled.section`
    width: 100%;
    height: ${props => props.$fullscreen ? '100vh' : 'auto'};
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto;
    margin-top: ${props => props.$fullscreen ? `calc(-1 * ${props.theme.variables.headerHeight})` : '0'};
    max-width: ${props => props.$fullscreen ? 'none' : `calc(${props.theme.variables.containerWidth} - ${props.theme.variables.containerPadding}*2)`};

    @media (min-width: calc(${props => props.theme.variables.containerWidth} - ${props => props.theme.variables.containerPadding}*2)) {
        border-radius: 0 0 ${props => props.theme.variables.borderRadius} ${props => props.theme.variables.borderRadius};
    };

    video, img, .video-embed {
        min-width: 101%;
        min-height: 101%;
        object-fit: cover;
    }

    .video-embed {
        padding: 0;

        & > div {
            margin: 0;
        }
    }
`;

export default function Hero({ nodes }) {

    const [isMobile, setIsMobile] = useState(window.innerWidth < responsiveBreakpoint);
    const videoRef = useRef();

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < responsiveBreakpoint);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.load();
        }
    }, [isMobile]);

    const renderMedia = () => {
        const desktop = {
            url: strapiUrl + nodes.desktop.data.attributes.url,
            mime: nodes.desktop.data.attributes.mime,
            type: nodes.desktop.data.attributes.mime.split('/')[0],
            alt: nodes.desktop.data.attributes.alternative
        }
        let media = desktop;
        if (nodes.mobile.data && isMobile) {
            media = {
                url: strapiUrl + nodes.mobile.data.attributes.url,
                mime: nodes.mobile.data.attributes.mime,
                type: nodes.mobile.data.attributes.mime.split('/')[0],
                alt: nodes.mobile.data.attributes.alternativeText
            }
        }
        if (media.type === 'video') {
            return (
                <video ref={videoRef} autoPlay muted loop playsInline alt={media.alt}>
                    <source src={media.url} type={media.mime} />
                </video>
            );
        } else if (media.type === 'image') {
            return <img src={media.url} alt={media.alt} />;
        }
        return null;

    };

    if (nodes && nodes.desktop.data) {
        return (
            <StyledHero $fullscreen={nodes.fullscreen}>
                {renderMedia()}
                {nodes.newsPopup ? <NewsPopup></NewsPopup> : null}
            </StyledHero>
        );
    } else if (nodes && nodes.videoEmbed) {
        return (
            <StyledHero $fullscreen={nodes.fullscreen}>
                <VideoEmbed nodes={nodes.videoEmbed} style={nodes.videoEmbed.container ? null : { marginTop: 0 }} />
            </StyledHero>
        )
    } else {
        return null;
    }
}