import { Menu, BurgerX } from "./";
import { React, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { device } from './device';
import { BsEnvelopeFill, BsLinkedin, BsYoutube } from 'react-icons/bs';

const strapiUrl = process.env.REACT_APP_STRAPI_URL;

const Logo = styled(Link)`
    height: 70%;
    display: flex;
    align-items: center;
    gap: 0.8em;
    z-index: 1000;
`;

const StyledHeader = styled.header`
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(8px);
    color: var(--green);
    height: var(--header-height);
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 1em;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;

    .mobile-menu, .burgerX {
        display: none;
    }

    .mobile-nav {
        position: fixed;
        top: var(--header-height);
        left: 0;
        height: calc(100vh - var(--header-height));
        overflow-y: auto;
        opacity: 0;
        pointer-events: none;
        transition: 0.2s;
        z-index: 1000;
        padding: 0 var(--container-padding);

        ul {
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 2em;
            width: auto;
            margin: 3em 0;
            list-style: none;
        }

        a {
            color: rgba(255, 255, 255, 0.5);
            transition: 0.2s;
            padding: 0.7em 0;
            padding-right: 1em;

            &.current-page {
                color: white;
            }

            &:hover {
                color: white;
            }
        }
        
        .social-icons {
            margin-bottom: 2em;
            a {
                font-size: 1.2em;
                margin-right: 0.5em;
                color: var(--green);
                background-color: rgba(255, 255, 255, 0.5);
                padding: 0.5em;
                display: inline-flex;
                border-radius: 50%;
                transition: 0.2s;

                &:hover {
                    transform: scale(1.1);
                    background-color: white;
                }

                &:active {
                    transform: scale(1);
                }
            }
        }
    }

    .expandible-circle-background {
        background-color: var(--green);
        width: 0;
        height: 0;
        transition: 0.2s;
        transition-timing-function: ease-in-out;
        position: absolute;
        transform: rotate(45deg);
    }

    .mobile-menu {
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        z-index: 999;
        pointer-events: none;

        hr {
            position: fixed;
            top: var(--header-height);
            right: var(--container-padding);
            width: 0;
            margin: 0;
            border: none;
            border-bottom: rgba(255, 255, 255, 0.3) 0.1em solid;
            z-index: 1000;
            transition: 0.2s;
        }
    }
    
    .expandible-container {
        position: absolute;
        right: var(--container-padding);
        top: calc(var(--header-height) / 2 - 20px);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5em;
        height: 2.5em;
        pointer-events: all;
        right: 1.8em;
    }
    
    .burgerX {
        position: absolute;
        padding: 1em;

        svg {
            stroke: var(--green);
            transition: 0.1s;
        }
    }
        
    .expandible-container:hover {
        .expandible-circle-background {
            width: 2.2em;
            height: 2.2em;
        }
        
        .burgerX svg {
            stroke: white;
        }
    }
    
    &.menu-open {
        .mobile-menu {
            pointer-events: all;
        }
    
        .expandible-circle-background {
            width: calc(150vh + 150vw) !important;
            height: calc(150vh + 150vw) !important;
        }
        
        .burgerX svg {
            stroke: white;
        }
        
        .mobile-nav {
            opacity: 1;
            pointer-events: all;
            transition-delay: 0.2s;
        }

        .mobile-menu hr {
            transition-delay: 0.1s;
            width: calc(100% - var(--container-padding) * 2);
        }
    }
    
    @media ${device.laptop} {
        .container>nav ul {
            display: none;
        }
    
        .mobile-menu {
            display: flex;
        }
    
        .burgerX {
            display: block;
        }
    
        &.menu-open ${Logo} {
            filter: grayscale(1) brightness(999);
            transition-delay: 0.2s;
        }
    }
`;

const HeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    &>nav ul {
        display: flex;
        align-items: center;
        gap: 1.8em;
        list-style: none;

        li a {
            color: var(--gray);
            transition: 0.2s;

            &.current-page {
                color: var(--green);
            }

            &:hover {
                color: var(--green);
            }
        }
    }
`;

const LogoImage = styled.img`
    height: 100%;
`;

export default function Header({ logo, tagline, menuItems, socialLinks, currentPage }) {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <StyledHeader className={menuOpen ? 'menu-open' : ''}>
            <HeaderContainer className="container">
                <Logo to="/" onClick={() => setMenuOpen(false)}>
                    <LogoImage src={strapiUrl + logo.url} alt={logo.alternativeText} />
                    {tagline}
                </Logo>
                <Menu items={menuItems} currentPage={currentPage} />
                <div className="mobile-menu">
                    <div className="expandible-container">
                        <div className="expandible-circle-background"></div>
                        <BurgerX onClick={() => setMenuOpen(menuOpen => !menuOpen)} isOpen={menuOpen} />
                    </div>
                    <hr />
                    <div className="mobile-nav">
                        <Menu items={menuItems} onClickAction={() => setMenuOpen(false)} currentPage={currentPage} />
                        <div className="social-icons">
                            <Link to={socialLinks.linkedin} target="_blank"><BsLinkedin /></Link>
                            <Link to={socialLinks.youtube} target="_blank"><BsYoutube /></Link>
                            <Link to={'mailto:' + socialLinks.email} target="_blank"><BsEnvelopeFill /></Link>
                        </div>
                    </div>
                </div>
            </HeaderContainer>
        </StyledHeader>
    );
}