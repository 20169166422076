import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToTop = (props) => {
  const location = useLocation();

  useEffect(() => {
    const scrollToElement = (hash, retries = 10) => {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        const offset = 60;
        const elementPosition = element.getBoundingClientRect().top + window.scrollY;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth"
        });
      } else if (retries > 0) {
        setTimeout(() => scrollToElement(hash, retries - 1), 100);
      }
    };

    if (location.hash) {
      scrollToElement(location.hash);
    } else {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return <>{props.children}</>;
};

export default ScrollToTop;