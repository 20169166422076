import styled from "styled-components"
import { useRef, useState, useEffect } from "react"
import Button from "./Button";
import { BsCaretDownFill } from "react-icons/bs";

const Excerpt = styled.div`
    position: absolute;
    transition: opacity 0.2s;
    user-select: none;
`;

const Full = styled.div`
    position: absolute;
    transition: opacity 0.2s;
    user-select: none;
`;

const ReadMoreButton = styled.span`
    margin-top: 0.5em !important;
    color: ${props => props.theme.color.green};
    background-color: ${props => props.theme.color.semiGreen};
    padding: 0.3em 0.6em;
    display: block;
    width: fit-content;
    border-radius: 0.6em;
`;

const ReadMoreSection = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
    transition: height 0.3s;

    p {
        margin-top: 0;
    }
`;

const Flex = styled.div`
    display: flex;
    gap: 0.5em;
    cursor: pointer;
    align-items: start;
`;

export default function ReadMore({ nodes, button, readLess=true, initiallyExpanded=false }) {
    const fullRef = useRef(null);
    const excerptRef = useRef(null);
    const [fullHeight, setFullHeight] = useState(0);
    const [excerptHeight, setExcerptHeight] = useState(0);
    const [expanded, setExpanded] = useState(false);

    useEffect(() => {
        if (fullRef.current) {
            setFullHeight(fullRef.current.offsetHeight);
            setExcerptHeight(excerptRef.current.offsetHeight);
        }

        const handleResize = () => {
            setFullHeight(fullRef.current.offsetHeight);
            setExcerptHeight(excerptRef.current.offsetHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function toggle() {
        setFullHeight(fullRef.current.offsetHeight);
        setExcerptHeight(excerptRef.current.offsetHeight);
        setExpanded(!expanded);
    }

    return (
        <Flex onClick={toggle} className={nodes.container ? 'container' : null}>
            <ReadMoreSection style={{ height: expanded ? fullHeight : excerptHeight }}>
                <Excerpt style={{ opacity: expanded ? '0' : '1', pointerEvents: expanded ? 'none' : 'all' }} ref={excerptRef}>
                {typeof nodes.excerpt === 'string' ? nodes.excerpt.split('\n').map((line, index) => <p key={index}>{line}</p>) : nodes.excerpt}
                <ReadMoreButton>Read more</ReadMoreButton>
                </Excerpt>
                <Full style={{ opacity: expanded ? '1' : '0', pointerEvents: expanded ? 'all' : 'none' }} ref={fullRef}>
                    {typeof nodes.excerpt === 'string' ? nodes.full.split('\n').map((line, index) => <p key={index}>{line}</p>) : nodes.full}
                    {readLess ? <ReadMoreButton>Read less</ReadMoreButton> : null}
                    {button && <Button roundedCorners={true} nodes={{link: button.link}} style={{marginTop: '1em'}}>{button.text}</Button>}
                </Full>
            </ReadMoreSection>
            <BsCaretDownFill style={{transform: expanded ? 'rotate(180deg)' : 'rotate(0)', transition: '0.2s', color: 'white', backgroundColor: 'var(--gray)', padding: '0.25em', flexBasis: '1.2em', height: '1.2em', borderRadius: '9999999px',}}/>
        </Flex>
    )
}