import React from 'react';

const resolveRichTextNode = (node, index) => {
    const children = node.children ? node.children.map((child, idx) => resolveRichTextNode(child, idx)) : null;

    switch (node.type) {
        case 'heading':
            return React.createElement(`h${node.level}`, { key: index }, children);
        case 'text':
            let content = node.text || '';

            if (node.bold) content = <b key={index}>{content}</b>;
            if (node.code) content = <code key={index}>{content}</code>;
            if (node.italic) content = <i key={index}>{content}</i>;
            if (node.strikethrough) content = <s key={index}>{content}</s>;
            if (node.underline) content = <u key={index}>{content}</u>;

            return content;
        case 'paragraph':
            return <p key={index}>{children}</p>;
        case 'link':
            return <a key={index} href={node.url}>{children}</a>;
        case 'list':
            return node.format === 'ordered' ? <ol key={index}>{children}</ol> : <ul key={index}>{children}</ul>;
        case 'list-item':
            return <li key={index}>{children}</li>;
        case 'quote':
            return <blockquote key={index}>{children}</blockquote>;
        case 'code':
            return <pre key={index}>{children}</pre>;
        case 'image':
            return (
                <img
                    key={index}
                    src={node.image.url}
                    height={node.image.height}
                    width={node.image.width}
                    alt={node.image.alternativeText}
                />
            );
        default:
            return null;
    }
};

const resolveRichText = (nodes) => {
    return nodes.map((node, index) => <React.Fragment key={index}>{resolveRichTextNode(node)}</React.Fragment>);
};

const RichText = ({ nodes }) => {
    return <section className='container'>{resolveRichText(nodes.content)}</section>;
};

export default RichText;