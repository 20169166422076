import styled from "styled-components";
import Button from "./Button";
import { device } from "../device";
import { useState, useEffect } from "react";
import { checkStatus, parseJSON } from "../../utils/apiUtils";
import { Link } from "react-router-dom";
import Loader from "../Loader";

const strapiUrl = process.env.REACT_APP_STRAPI_URL;
const headers = { "Content-Type": "application/json" };

const Thumbnail = styled.div`
    width: 18em;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: box-shadow 0.5s;
        border-radius: 0.6em;
    }
`;

const Content = styled.div`
    width: calc(100% - 18em);
`;

const Entry = styled(Link)`
    display: flex;
    gap: 1em;
    padding: 1em;
    align-items: stretch;
    height: auto;
    width: 100%;
    background-color: ${props => props.theme.color.semiGray2};
    transition: 0.3s;
    border-radius: 0.6em;

    h3 {
        margin: 0.2em 0;
        color: ${props => props.theme.color.green};
    }
    
    p {
        margin: 0.2em 0;
    }

    hr {
        margin: 1em 0;
        width: 80%;
        transition: 0.3s;
    }

    &:hover {
        background-color: ${props => props.theme.color.semiGray};

        img {
            box-shadow: 0 0 1em rgba(0, 0, 0, 0.1);
        }

        hr {
            width: 100%;
        }
    }

    span {
        color: ${props => props.theme.color.green};
    }

    @media ${device.tablet} {
        flex-direction: column;
        gap: 0.5em;

        ${Thumbnail} {
            width: 100%;
        }

        ${Content} {
            width: 100%;
        }
    }
`;

const NewsSection = styled.section`
    display: flex;
    flex-direction: column;
    gap: 0.5em;
`;

const StyledHr = styled.hr`
    border: 0;
    border-top: 1px solid ${props => props.theme.color.gray};
`;

function formatDate(dateString) {
    const date = new Date(dateString);

    const monthName = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date);

    const day = date.getDate();
    const year = date.getFullYear();

    const ordinalSuffix = (n) => {
        if (n > 3 && n < 21) return 'th';
        switch (n % 10) {
            case 1: return 'st';
            case 2: return 'nd';
            case 3: return 'rd';
            default: return 'th';
        }
    };

    const dayWithSuffix = day + ordinalSuffix(day);

    return `${monthName} ${dayWithSuffix}, ${year}`;
}

export default function News({ nodes }) {

    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(`${strapiUrl}/api/articles?sort[0]=published:desc&populate[heading]=true&populate[location]=true&populate[published]=true&populate[excerpt]=true&populate[banner][populate]=*`, { headers, method: "GET" })
            .then(checkStatus)
            .then(parseJSON)
            .then(({ data }) => {
                setNews(data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
            });
    }, []);

    if (error) {
        return <div>An error occurred: {error.message}</div>;
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <NewsSection className="container">
            {
                news.map((article, index) => (
                    <Entry key={index} to={`/news/${article.attributes.slug}`}>
                        <Thumbnail>
                            <img src={strapiUrl + article.attributes.banner.desktop.data.attributes.formats.medium.url} alt={article.attributes.heading} />
                        </Thumbnail>
                        <Content>
                            <h3>{article.attributes.heading}</h3>
                            <StyledHr />
                            <p><span>{article.attributes.location} · {formatDate(article.attributes.published)}</span> | {article.attributes.excerpt}...</p>
                        </Content>
                    </Entry>
                ))
            }
            {nodes.preview && <Button fill={false} nodes={{link: '/clients'}}>All testimonials</Button>}
        </NewsSection>
    )
}