import { Link } from "react-router-dom";
import styled from "styled-components";
import { BsEnvelopeFill } from "react-icons/bs";

const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    gap: 0.6em;
    color: ${props => props.theme.color.green};
    transition: 0.2s;
    width: fit-content;
    padding: 0.3em 0.6em;
    border-radius: 0.6em;
    border: 0.1em solid ${props => props.theme.color.green};

    &:hover {
        color: white;
        background-color: ${props => props.theme.color.green};
    }
`;

export default function EmailAdress({ nodes }) {
    const { email, container } = nodes;
    return (
        <section className={container ? 'container' : null}>
            <StyledLink to={`mailto:${email}`}>
                <BsEnvelopeFill style={{fontSize: '1.5em'}} />
                {email}
            </StyledLink>
        </section>
    );
}