import styled from "styled-components";
import Button from "./Button";
import { useState, useEffect } from "react";
import { checkStatus, parseJSON } from "../../utils/apiUtils";
import Testimonial from "./Testimonial";
import Loader from "../Loader";

const strapiUrl = process.env.REACT_APP_STRAPI_URL;
const headers = { "Content-Type": "application/json" };

const StyledTestimonialSection = styled.section`
    display: flex;
    flex-direction: column;
    gap: 0.5em;
`;

export default function TestimonialSection({ nodes }) {

    const [testimonials, setTestimonials] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetch(`${strapiUrl}/api/testimonials?populate=deep&sort=order:asc`, { headers, method: "GET" })
            .then(checkStatus)
            .then(parseJSON)
            .then(({ data }) => {
                setTestimonials(data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
            });
    }, []);

    if (error) {
        return <div>An error occurred: {error.message}</div>;
    }

    if (loading) {
        return <Loader />;
    }

    let testimonialCount = 0;

    return (
        <StyledTestimonialSection className="container">
            {
                testimonials.map((testimonial, index) => {
                    if (testimonial.attributes.order && (!nodes.preview || testimonialCount < 4)) {
                        testimonialCount++;

                        return (
                            <Testimonial key={index} avatarUrl={strapiUrl + testimonial.attributes.avatar.data.attributes.url} name={testimonial.attributes.name} position={testimonial.attributes.position} client={testimonial.attributes.client.data.attributes} quote={testimonial.attributes.quote} project={testimonial.attributes.project} />
                        )
                    } else {
                        return null;
                    }
                })
            }
            {nodes.preview && <Button roundedCorners={true} fill={false} nodes={{link: '/clients'}}>All testimonials</Button>}
        </StyledTestimonialSection>
    )
}