import { checkStatus, parseJSON } from "../../utils/apiUtils";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Loader from "../Loader";

const headers = { "Content-Type": "application/json" };
const strapiUrl = process.env.REACT_APP_STRAPI_URL;

const StyledNewsPopup = styled.div`
    width: 100%;
    position: absolute;
    bottom: 1em;
    
    div {
        box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
        background-color: rgba(255, 255, 255, 0.8);
        max-width: 30vw;
        min-width: 15em;
        padding: 1em;
        border-radius: 1em;
        transition: 0.2s;

        a {
            color: var(--gray);
        }
        
        span {
            color: var(--green);
        }

        &:hover {
            transform: scale(1.02);
        }
    }
`;

export default function NewsPopup() {
    const [error, setError] = useState(null);
    const [articles, setArticles] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetch(`${strapiUrl}/api/articles?fields[0]=heading&fields[1]=slug&sort=published:desc`, { headers, method: "GET" })
            .then(checkStatus)
            .then(parseJSON)
            .then(({ data }) => {
                setArticles(data);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
            });
    }, []);

    if (error) {
        return <div>An error occurred: {error.message}</div>;
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <StyledNewsPopup className="container">
            <div>
                <span>news</span> ·
                <Link to={'/news/' + articles[0].attributes.slug}> {articles[0].attributes.heading}... <span>read more</span></Link>
            </div>
        </StyledNewsPopup>
    )
}